<template>
  <div class="bg_box">
    <div class="isbox">
      <div class="logo">
        <img src="../assets/img/logo_white.png" alt="">
      </div>
      <div class="isRight">
        <div v-for="(item, index) in showText" :key="index">{{ item }}</div>
        <div class="isBtn" @click="getUrl">立即下载</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeadView',
  data() {
    return {
      showText: [
        '微信多开', '微信双开', '微信分身', '多微信登录'
      ],
      clickBtuuonRepeat:false
    }
  },
}
</script>
<style lang="scss" scoped>
.bg_box {
  width: 100%;
  height: 60px;
  position: fixed;
  background-color: #000;
  z-index: 10;

  .isbox {
    width: 1280px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 102px;
      height: 32px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .isRight {
      width: 500px;
      display: flex;
      justify-content: space-around;
      line-height: 32px;

      font-size: 16px;
      font-weight: 500;
      color: #fff;

      .isBtn {
        background: #26C867;
        width: 91px;
        height: 32px;
        border-radius: 4px;
        color: #fff;
        line-height: 32px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>