import axios from 'axios'
export default {
    install(Vue) {
        Vue.mixin({
            data(){
                return {

                  companyName:'',
                  companyArr:[
                    {host:'localhost:8080',name:''},
                    {host:'wetalk.kafankj.com',name:''},
                    {host:'wetalk.ncdsnc.com',name:'芜湖界佳网络科技有限公司湖南分公司'},
                    {host:'wetalk.hfyhwl.cn',name:'合肥宇寰网络科技有限公司'},
                  ],
                  hostArr: [
                    { host: 'localhost:8080', src: 'https://hm.baidu.com/' },
                    { host: 'wetalk.ncdsnc.com', src: 'https://hm.baidu.com/hm.js?c2626c644f3b5bd343178552cee62851' },
                    { host: 'wetalk.hfyhwl.cn', src: 'https://hm.baidu.com/hm.js?a0b9771d622cd1a2c083665c7ad0b09c' },
                  ]
                }
            },
            mounted() {
              this.companyName = this.companyArr.find(item =>item.host == window.location.host).name || ''
            },
            methods:{
                getUrl() {
                    if (this.clickBtuuonRepeat) return
                    let params = {
                      tg_id: sessionStorage.getItem('tg_id') == 'null' ? '0' : sessionStorage.getItem('tg_id'),
                      tg_type: sessionStorage.getItem('tg_type') == 'null' ? 0 : Number(sessionStorage.getItem('tg_type')),
                      cid: sessionStorage.getItem('pdf_id') == 'null' ? 0 : Number(sessionStorage.getItem('pdf_id')),
                      tg_url_type: sessionStorage.getItem('tg_url_type') == 'null' ? 0 : Number(sessionStorage.getItem('tg_url_type')),
                      url: window.location.host
                    }
              
                    if ( params.tg_id == '0' ) {
                        params.cid = 0
                    }

                    let url = 'https://api.wetalk.kafankj.com/tg/getTgUrl'
                    axios.post(url, params).then((res) => {
                      let downUrl = ''
              
                      if (res.data.code == 0) {
                        downUrl = res.data.data.detail.tg_url
                        const a = document.createElement('a')
                        a.href = downUrl
                        document.body.appendChild(a)
                        a.click()
                        a.remove()
                        
                        let isCallbackurl = 'https://api.wetalk.kafankj.com/tg/download/callback'
                
                        axios.post(isCallbackurl, { tg_id: sessionStorage.getItem('tg_id') == 'null' ? '0' : sessionStorage.getItem('tg_id') }).then((res) => {
                          console.log(res);
                        })
                      } else {
                        this.$message.error(res.data.msg)
                      }
              
                    }) .catch(() =>{
                      this.$message.error('网络错误，请稍后再试')
                    })

                    this.click_btn('点击下载按钮')

                    this.clickBtuuonRepeat = true
                    setTimeout(() => {
                      this.clickBtuuonRepeat = false
                    }, 2000)
              
                  },
                click_btn(category,label = '',action = 'click') {
                try {
                  let domainHost = window.location.host
                  let result_cnzz = this.hostArr.find((item) => item.host == domainHost) || { src: 'https://hm.baidu.com/' }
                  if (result_cnzz && result_cnzz.src) {
                    var _hmt = _hmt || []
                    ;(function () {
                      var hm = document.createElement('script')
                      hm.src = result_cnzz.src ? result_cnzz.src : ''
                      var s = document.getElementsByTagName('script')[0]
                      s.parentNode.insertBefore(hm, s)
                    })()
                  }
                    _hmt.push(['_trackEvent',category,action,label])
                } catch (error) {
                  console.log(error);
                }
                },
            }
        })
    }
}